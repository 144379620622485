<template>
  <div @click.prevent="showList">
    <BootstrapDropdown
      :btnIcon="'caret-down'"
      :btnClass="'btn btn-circle btn-circle-lg text-white'"
      class="cta"
    >
      <li v-for="(client, clientIdx) in sortedClients" :key="`key-${clientIdx}`">
        <a @click.prevent="viewClient(client)" class="dropdown-item small">
          {{ client | fullname }}
        </a>
      </li>
      <li v-if="!isLoading && !sortedClients.length">
        <div class="dropdown-item no-interaction">No clients</div>
      </li>
      <li v-if="isLoading">
        <LoadingMessage></LoadingMessage>
      </li>
    </BootstrapDropdown>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NavBarClientList',
  components: {
    BootstrapDropdown: () => import('@/components/bootstrap/BootstrapDropdown'),
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
  },
  computed: {
    ...mapState('client', ['clients']),
    sortedClients() {
      const { clients } = this;
      if (clients === null) return [];
      return clients.sort((a, b) => {
        const nameA = a.firstName.toUpperCase();
        const nameB = b.firstName.toUpperCase();
        let cmp = 0;
        if (nameA > nameB) cmp = 1;
        if (nameA < nameB) cmp = -1;
        return cmp;
      });
    },
  },
  methods: {
    viewClient(client, tab = 'calendar') {
      this.$router.push(`/client/${client.userId}/#${tab}`).catch(() => {});
    },
    async showList() {
      if (this.clients === null) {
        this.isLoading = true;
        try {
          await this.$store.dispatch('client/fetchClients');
        } catch (err) {
          this.$store.dispatch('addSystemError', err, { root: true });
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.cta {
  margin-left: .125rem;
  margin-right: -.5rem;
  margin-top: -2px;
}
</style>
